import { VariantType } from 'notistack';
import { enqueueSnackbar } from 'notistack';

const showSnackbar = (type: VariantType, text: string): void => {
    enqueueSnackbar(text, { variant: type })
};

export const snackbar = {
    default: (text: string) => showSnackbar('default', text),
    success: (text: string) => showSnackbar('success', text),
    warning: (text: string) => showSnackbar('warning', text),
    error: (text: string) => showSnackbar('error', text),
    info: (text: string) => showSnackbar('info', text),
};