import { useState, forwardRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Stack, Box } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useResponsive } from 'hooks/use-responsive';
import { useDispatch, useSelector } from 'store';
import { signout } from 'store/reducers/auth';
import { logout } from 'api/user';

import HistoryDialogView from 'sections/setting/history';

// import ModalBackground from 'assets/images/modal-background.png';
import ModalBackground from 'assets/images/modal/base.png';
import SettingsTitle from 'assets/images/header/settings/settings.png';
import CloseButton from 'assets/images/modal/close.png';

import ButtonBg from 'assets/images/header/settings/button.png';
import EnglishBg from 'assets/images/header/settings/language.png';
import HistoryButton from 'assets/images/header/settings/history.png';
import LogoutButton from 'assets/images/header/settings/logout.png';
import LanguageLabel from 'assets/images/header/settings/language_label.png';
import HistoryLabel from 'assets/images/header/settings/history_label.png';
import LogoutLabel from 'assets/images/header/settings/logout_label.png';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

type PropsType = {
    status: boolean;
    onClose: () => void;
};

const SettingDialogView = ({ status, onClose }: PropsType) => {
    // const { isMobile } = useResponsive();
    const { isLoggedIn } = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [historyModalStatus, setHistoryModalStatus] = useState<boolean>(false);

    const closeModal = () => {
        onClose();
    };

    const handleUserLogout = async () => {
        await logout();

        localStorage.clear();
        dispatch(signout());

        closeModal();
    };

    return (
        <>
            <Dialog
                // fullScreen={isMobile ? true : false}
                open={status}
                TransitionComponent={Transition}
                maxWidth="md"
                fullWidth={true}
                sx={{
                    '& .MuiModal-backdrop': {
                        backdropFilter: "blur(8px)"
                    },
                    '& .MuiDialog-paper': {
                        ...(isMobile ? { maxHeight: "100%" } : { maxHeight: "80%" }),
                        width: { xs: "90%" },
                        p: 3,
                        backgroundColor: "unset",
                        boxShadow: "unset",
                    }
                }}
            >
                <Box component="img" src={ModalBackground} sx={{ position: 'absolute', width: 0.95, height: 0.95, zIndex: 0 }} />
                <IconButton
                    onClick={closeModal}
                    sx={{ position: "absolute", right: 0, top: 0, height: 80, p: 1, zIndex: 99 }}
                >
                    <Box component="img" src={CloseButton} height={1} sx={{ '&:hover': { filter: "blur(1px)" } }} />
                </IconButton>

                <DialogTitle sx={{ display: "flex", justifyContent: "center", alignItems: "center", fontWeight: 900, height: 50, zIndex: 2, mt: 2 }}>
                    <Box component="img" src={SettingsTitle} />
                </DialogTitle>

                <DialogContent sx={{ zIndex: 2 }}>
                    <Stack
                        direction="row" justifyContent="space-between" alignItems="center" gap={4}
                        sx={{ padding: 1.2, px: "10%" }}
                    >
                        <Stack sx={{ alignItems: 'center' }}>
                            <IconButton
                                onClick={closeModal}
                                sx={{ "&:hover": { filter: "brightness(150%)" } }}
                            >
                                <Box
                                    component="img"
                                    src={ButtonBg}
                                    height={1}
                                    width={1}
                                />
                                <Box
                                    component="img"
                                    src={EnglishBg}
                                    sx={{ position: 'absolute', zIndex: 0 }}
                                />
                            </IconButton>
                            <Box component="img" src={LanguageLabel} sx={{ width: 115 }} />
                        </Stack>

                        <Stack sx={{ alignItems: 'center' }}>
                            <IconButton
                                onClick={() => { isLoggedIn && setHistoryModalStatus(true) }}
                            >
                                <Box
                                    component="img"
                                    src={HistoryButton}
                                    height={1}
                                    width={1}
                                    sx={{ cursor: "pointer", "&:hover": { filter: "brightness(150%)" } }}
                                />
                            </IconButton>
                            <Box component="img" src={HistoryLabel} sx={{ width: 115 }} />
                        </Stack>

                        <Stack sx={{ alignItems: 'center' }}>
                            <IconButton
                                onClick={() => { isLoggedIn && handleUserLogout() }}
                            >
                                <Box
                                    component="img"
                                    src={LogoutButton}
                                    height={1}
                                    width={1}
                                    sx={{ cursor: "pointer", "&:hover": { filter: "brightness(150%)" } }}
                                />
                            </IconButton>
                            <Box component="img" src={LogoutLabel} sx={{ width: 115 }} />
                        </Stack>
                    </Stack>
                </DialogContent>
            </Dialog>

            <HistoryDialogView status={historyModalStatus} onClose={() => setHistoryModalStatus(false)} />
        </>
    );
};

export default SettingDialogView;