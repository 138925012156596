import { useState, ChangeEvent } from "react";
import { Button, InputBase, Stack, TextField } from "@mui/material";
import { UserLoginType } from "types";
import useApi from "hooks/use-api";
import { useDispatch } from 'store';
import { signin } from 'store/reducers/auth';
import { remove, set } from 'store/reducers/modal';
import { snackbar } from "utils/alert";

import LoginBackground from 'assets/images/header/login/login.png';
import InputBg from 'assets/images/header/login/input.png';

const LoginView = () => {
    const dispatch = useDispatch();
    const { login } = useApi();
    const [userData, setUserData] = useState<UserLoginType>({
        email: "",
        password: "",
    });

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const userLogin = async () => {
        try {
            const response = await login(userData);
            if (response?.data?.user && response?.data?.token) {
                dispatch(signin(response.data));
                dispatch(remove());
                snackbar.success('Welcome!');
                // dispatch(set("rule"))
                return;
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Stack mt={3} gap={2} justifyContent="center" alignItems="center">
            {/* <Stack direction="column" gap={4} sx={{ backgroundImage: `url(${ModalContentBackground})`, backgroundSize: "100% 100%", px: 1.2, py: 3, borderRadius: 1 }}> */}
            <InputBase
                name="email"
                value={userData.email}
                onChange={handleChange}
                fullWidth
                placeholder="USERNAME"
                sx={{
                    "& input": {
                        backgroundImage: `url(${InputBg}) !important`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        textAlign: 'center',
                        borderRadius: 40,
                        py: 1.5,
                    }
                }}
            />
            <InputBase
                type="password"
                name="password"
                value={userData.password}
                onChange={handleChange}
                fullWidth
                placeholder="PASSWORD"
                sx={{
                    "& input": {
                        backgroundImage: `url(${InputBg}) !important`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        textAlign: 'center',
                        borderRadius: 40,
                        py: 1.5,
                    }
                }}
            />
            {/* </Stack> */}

            {/* <Stack direction="row" gap={1}>
                <Typography color="white">New User? </Typography>
                <Typography color="primary" sx={{ cursor: "pointer" }} onClick={() => dispatch(set("register"))}>Create an account</Typography>
            </Stack> */}

            <Button
                sx={{
                    backgroundImage: `url(${LoginBackground})`,
                    backgroundSize: "100% 100%",
                    width: 200,
                    color: "#21283b",
                    fontWeight: 600,
                    py: 4,
                    borderRadius: 50,
                    '&:hover': {
                        filter: "brightness(120%)"
                    }
                }}
                onClick={userLogin}
            />
        </Stack>
    );
};

export default LoginView;