import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
    AppBar,
    Box,
    Stack,
    Toolbar,
    Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'store';
import { set } from 'store/reducers/modal';
import { turnOn } from 'store/reducers/audio';
import { setFavorite } from 'store/reducers/auth';

import ButtonClickAudio, { ButtonClickAudioRef } from 'components/audio/button-click';
import BackgroundAudio from 'components/audio/background';
import CoinBG from 'assets/images/header/coins.png';

import Logo from 'components/logo';
import FavoriteButtonBG from 'assets/images/header/favourites.png';
import LoginButtonBG from 'assets/images/header/login.png';
import PlayerSectionBG from 'assets/images/header/player bar.png';
import SettingButtonBG from 'assets/images/header/settings.png';
import SettingDialogView from 'sections/setting';
import SoundButtonBG from 'assets/images/header/sound.png';
import SoundOffButtonBG from 'assets/images/header/sound_off.png';

function Header() {
    const dispatch = useDispatch();
    const { isLoggedIn, user, favoriteStatus } = useSelector((state) => state.auth);
    const { volume } = useSelector((state) => state.audio);

    const buttonClickAudioRef = useRef<ButtonClickAudioRef>(null);

    const [settingModalStatus, setSettingModalStatus] = useState(false);


    const openFavorite = () => {
        dispatch(setFavorite(isLoggedIn && !favoriteStatus));
    };

    const openAuthModal = (value: string) => {
        dispatch(set(value));
    };

    const openSettingModal = () => {
        setSettingModalStatus(true);
    };

    const handleChangeVolumn = () => {
        dispatch(turnOn(volume > 0 ? 0 : 1));
    };

    return (
        <>
            <AppBar position="fixed" sx={{ background: "none", boxShadow: "none" }}>
                <Toolbar disableGutters sx={{ justifyContent: "center", alignItems: "flex-start", minHeight: "70px !important" }}>
                    <Stack direction="row" justifyContent="center" >
                        <Stack direction="row" position="absolute" >
                            <Box
                                component="img"
                                src={FavoriteButtonBG}
                                sx={{
                                    position: "absolute",
                                    height: isMobile ? 41 : 77,
                                    left: isMobile ? -334 : -661,
                                    cursor: "pointer",
                                    zIndex: 999,
                                    ...(favoriteStatus && {
                                        filter: "brightness(140%)",
                                    }),
                                    "&:hover": { filter: "brightness(150%)" }
                                }}
                                onClick={openFavorite}
                            />
                            <Box
                                component="img"
                                src={SettingButtonBG}
                                sx={{
                                    position: "absolute",
                                    height: isMobile ? 44 : 80,
                                    left: isMobile ? -274 : -548,
                                    cursor: "pointer",
                                    zIndex: 998,
                                    "&:hover": { filter: "brightness(150%)" }
                                }}
                                onClick={openSettingModal}
                            />
                            <Box
                                component="img"
                                src={SoundButtonBG}
                                sx={{
                                    position: "absolute",
                                    height: isMobile ? 44 : 80,
                                    left: isMobile ? -204 : -425,
                                    cursor: "pointer",
                                    zIndex: 997,
                                    ...(volume > 0 && {
                                        filter: "brightness(140%)",
                                    }),
                                    "&:hover": { filter: "brightness(150%)" }
                                }}
                                onClick={handleChangeVolumn}
                            />
                        </Stack>
                        <Logo
                            sx={{
                                // height: isMobile ? "auto" : 66,
                                width: isMobile ? 205 : 483,
                                justifyContent: "center"
                            }}
                        />
                        <Stack direction="row" position="absolute">
                            <Stack
                                sx={{
                                    ml: -3,
                                    height: isMobile ? 44 : 80,
                                    right: isMobile ? -270 : -544,
                                    alignItems: 'center',
                                    position: "absolute",
                                    "&:hover": { filter: "brightness(150%)" }
                                }}>
                                <Box
                                    component="img"
                                    src={PlayerSectionBG}
                                    sx={{ height: 1 }}
                                />
                                <Stack sx={{ position: "absolute", mr: 1 }}>
                                    <Typography sx={{
                                        fontSize: isMobile ? 11 : 20, fontWeight: 800,
                                        ...(isMobile && {
                                            mt: 0.5
                                        })
                                    }}><span style={{ color: "#FFD700", fontSize: isMobile ? 11 : 20, }}>Player</span> {user.name || ""}</Typography>
                                    <Stack position="relative" textAlign="center" alignItems="center" >
                                        <Box
                                            component="img"
                                            src={CoinBG}
                                            sx={{ height: isMobile ? "auto" : 30, width: isMobile ? 77 : 140 }}
                                        />
                                        <Typography sx={{
                                            position: "absolute",
                                            fontSize: isMobile ? 11 : 20, fontWeight: 800,
                                        }}>
                                            {user.balance || 0}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Box
                                component="img"
                                src={LoginButtonBG}
                                sx={{
                                    position: "absolute",
                                    cursor: "pointer",
                                    height: isMobile ? 42 : 76,
                                    right: isMobile ? -324 : -640,
                                    opacity: isLoggedIn ? 0.5 : 1,
                                    ...(!isLoggedIn && {
                                        "&:hover": { filter: "brightness(150%)" }
                                    }),
                                }}
                                onClick={() => !isLoggedIn && openAuthModal("login")}
                            />
                        </Stack>
                    </Stack>

                </Toolbar>
            </AppBar>

            <SettingDialogView status={settingModalStatus} onClose={() => setSettingModalStatus(false)} />

            <BackgroundAudio />
            <ButtonClickAudio ref={buttonClickAudioRef} />
        </>
    );
};

export default Header;
