import { forwardRef } from 'react';
import { isMobile } from 'react-device-detect';
import { Dialog, DialogContent, DialogTitle, IconButton, Slide, Box, Stack } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useDispatch, useSelector } from 'store';
import { remove } from 'store/reducers/modal';

import LoginView from 'sections/auth/login';
import PasswordChangeView from 'sections/auth/password-change';

// import ModalBackground from 'assets/images/modal-background.png';
import ModalBackground from 'assets/images/modal/base.png';
import CloseButton from 'assets/images/modal/close.png';
import Logo from 'assets/images/logo/logo.png';
// import CloseButton from 'assets/images/buttons/close-button.png';

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AuthDialogView = () => {
    const dispatch = useDispatch();
    // const { isMobile } = useResponsive();
    const { name } = useSelector(state => state.modal);

    const closeModal = () => {
        dispatch(remove());
    };

    return (
        <Dialog
            // fullScreen={isMobile ? true : false}
            open={name !== ""}
            TransitionComponent={Transition}
            maxWidth="xs"
            fullWidth={true}
            sx={{
                '& .MuiModal-backdrop': {
                    backdropFilter: "blur(8px)"
                },
                '& .MuiDialog-paper': {
                    ...(isMobile ? { maxHeight: "100%" } : { maxHeight: "80%" }),
                    width: { xs: "90%" },
                    p: 3,
                    backgroundColor: "unset",
                    boxShadow: "unset",
                }
            }}
        >
            <Box component="img" src={ModalBackground} sx={{ position: 'absolute', width: 0.9, height: 0.9 }} />
            <IconButton
                onClick={closeModal}
                sx={{ position: "absolute", right: 0, top: 0, height: 80, p: 1, zIndex: 99 }}
            >
                <Box component="img" src={CloseButton} height={1} sx={{ '&:hover': { filter: "blur(1px)" } }} />
            </IconButton>

            <DialogTitle
                variant={isMobile ? "h6" : "h5"}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontWeight: 900,
                    textTransform: "uppercase",
                    color: "white",
                    position: "relative"
                }}
            >
                <Box component="img" src={Logo} sx={{ position: "absolute", top: 0, width: 325, }} />

            </DialogTitle>

            <DialogContent>
                <Stack p={5}>
                    {name === "login" && <LoginView />}
                    {name === "password-change" && <PasswordChangeView closeModal={closeModal} />}
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default AuthDialogView;