import { createSlice } from '@reduxjs/toolkit';

type initialStateType = {
  name: string;
};

const initialState: initialStateType = {
  name: ''
};

const modal = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    set(state, action) {
      state.name = action.payload;
    },

    remove(state) {
      state.name = '';
    },
  },
});

export default modal.reducer;

export const { set, remove } = modal.actions;
