import { Provider } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider, styled } from '@mui/material';
import { store } from 'store';
import { ApiProvider } from 'contexts/ApiContext';
import Router from "routes/sections";
import theme from 'theme';

import SnackbarProvider from 'components/snackbar/snackbar-provider';

//import CSS
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

// image
import 'react-lazy-load-image-component/src/effects/blur.css';

import "assets/styles/index.scss";

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <ApiProvider>
            <Router />
          </ApiProvider>
          <Toaster />
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
