import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    position: "relative",
                    backgroundColor: "#000000"
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                outlinedInherit: {
                    color: "#FFF"
                },
                containedInherit: {
                    color: "#000000"
                }
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: "#FFF"
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: "#FFF"
                }
            }
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    margin: 0
                }
            }
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    color: "#FFF",
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "#FFF",
                    "&.Mui-focused": {
                        color: "#FFF",
                    },
                    "&.Mui-disabled": {
                        color: "#FFF",
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "#FFF",
                    "&:after": {
                        border: "#FFF",
                    }
                }
            }
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    color: "#FFF",
                    "& .Mui-disabled": {
                        WebkitTextFillColor: "#FFF !important",
                    },
                    "&:before, &:after, &:hover:not(.Mui-disabled, .Mui-error):before": {
                        borderColor: "#FFF",
                    },
                }
            }
        },
    },
});

export default theme;
