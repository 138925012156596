export const setFullScreen = async () => {
    try {
        if (document.documentElement.requestFullscreen) {
            await document.documentElement.requestFullscreen();
        }

        // if (document.documentElement.requestFullscreen) {
        //     await document.documentElement.requestFullscreen();
        // } else if (document.documentElement.mozRequestFullScreen) {
        //     await document.documentElement.mozRequestFullScreen();
        // } else if (document.documentElement.webkitRequestFullscreen) {
        //     await document.documentElement.webkitRequestFullscreen();
        // } else if (document.documentElement.msRequestFullscreen) {
        //     await document.documentElement.msRequestFullscreen();
        // }
    } catch (error) {
        console.log(error);
    }
};