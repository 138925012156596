import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'store';

const BackgroundAudio = () => {
    const { volume } = useSelector((state) => state.audio);

    const audioRef = useRef<HTMLAudioElement>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const playAudio = () => {
        const audio = audioRef.current;
        if (!audio) return;
        
        audio.play().then(() => {
            console.log('audio played');
        }).catch((error) => {
            console.log('audio error', error);
        });
    };

    const pauseAudio = () => {
        const audio = audioRef.current;
        if (!audio) return;

        audio.pause();
    };

    useEffect(() => {
        if (!isLoaded) return;
        if (volume > 0) {
            playAudio();
        } else {
            pauseAudio();
        }
    }, [volume, isLoaded]);

    return (
        <audio ref={audioRef} src="./backgroundMusic.mp3" loop style={{ display: "none" }} onLoadedData={() => setIsLoaded(true)} />
    );
};

export default BackgroundAudio;