import React, { createContext, useEffect } from 'react';
import axios from 'utils/axios';
import { API_PATH } from 'config-global';
import {
  ApiContextType,
  UserRegisterType,
  UserLoginType,
} from 'types';
import { useDispatch } from 'store';
import { edit } from 'store/reducers/auth';

const ApiContext = createContext<ApiContextType | null>(null);
/* eslint-disable */
export const ApiProvider = ({ children }: { children: React.ReactElement }) => {
  const dispatch = useDispatch();

  const initialize = async () => {
    const res = await axios.post(API_PATH.AUTH_CHECK);
    return dispatch(edit(!res?.data ? {} : res.data));
  };

  useEffect(() => {
    initialize();
  }, []);

  const register = async (data: UserRegisterType) => {
    return await axios.post(API_PATH.AUTH_REGISTER, data);
  };

  const login = async (data: UserLoginType) => {
    return await axios.post(API_PATH.AUTH_LOGIN, data);
  };

  return (
    <ApiContext.Provider
      value={{
        // initialize,
        register,
        login,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContext;
