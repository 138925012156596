import Box, { BoxProps } from '@mui/material/Box';

import Link from '@mui/material/Link';
import LogoBgImage from "assets/images/logo/base.png";
import LogoImage from "assets/images/logo/text.png";
import LogoGif from "assets/images/logo/logo.gif";
// routes
import { RouterLink } from 'routes/components';
import { forwardRef } from 'react';
import { isMobile } from 'react-device-detect';
// @mui
import { useTheme } from '@mui/material/styles';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const theme = useTheme();

    const PRIMARY_LIGHT = theme.palette.primary.light;

    const PRIMARY_MAIN = theme.palette.primary.main;

    const PRIMARY_DARK = theme.palette.primary.dark;

    // OR using local (public folder)
    // -------------------------------------------------------
    const logo = (
      <Box
        component="img"
        src={LogoGif}
        sx={{ cursor: 'pointer', ...sx }}
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Box width={1} zIndex={99} pt={isMobile ? 0.5 : 1.1}>
        <Box className="vegastar-logo">
          <Box
            component="img"
            src={LogoBgImage}
            sx={{ position: 'absolute', width: isMobile ? 245 : 560, cursor: 'pointer' }}
          />
          <Link component={RouterLink} href="/">
            {logo}
          </Link>
        </Box>

        {/* <svg height="0" width="100%">
          <mask id="mask-firefox">
            <image href={LogoImage} filter="url(#filter)" width="100%" />
          </mask>

          <filter id="filter">
            <feFlood floodColor="white" />
            <feComposite in2="SourceAlpha" operator="in" />
          </filter>
        </svg> */}
      </Box>
    );
  }
);

export default Logo;
